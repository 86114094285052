/**
 * Template broadcast text using this template
 * https://docs.google.com/document/d/1HtS6aBlI5BfvaaG4G5MGvRhljaxIlrAAGsSOecutXQ0/edit
 */
export const MESSAGE = {
  // for Bahasa Indonesia invitation - general
  ID_GENERAL_INVITATION: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Dengan segala kerendahan hati, kami berniat ingin mengundang Bapak/Ibu/Saudara/i untuk hadir dalam acara pernikahan kami: 

*{{BOY_NAME}}*
{{BOY_FATHER_NAME_ID}} dan Ibu {{BOY_MOTHER_NAME}}_
& 
*{{GIRL_NAME}}*
{{GIRL_FATHER_NAME_ID}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara akan diselenggarakan pada:
Hari: {{BROADCAST_WEDDING_DAY}}
Waktu: {{TIME}}
Lokasi: {{BROADCAST_WEDDING_LOCATION}}

*Tanpa mengurangi rasa hormat, berikut link undangan website kami:*
{{LINK}}
_*) Alasan keamanan WhatsApp, jika link tidak clickable (tidak berwarna biru), silakan simpan nomor ini untuk dapat membuka link._

Kehadiran Bapak/Ibu/Saudara/i tentu akan menjadi kehormatan besar bagi kami dan keluarga. Semoga kita semua dapat segera bertemu dalam keadaan sehat.

Terima kasih.

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_`,

  // for English invitation - general
  EN_GENERAL_INVITATION: `Dear Mr/Ms/Mrs,
{{GUEST_NAME}}

Together with the families, we cordially request the honor of your presence at the wedding celebration of: 

{{BOY_NAME}}
{{BOY_FATHER_NAME_EN}} and Mrs. {{BOY_MOTHER_NAME}}
& 
{{GIRL_NAME}} 
{{GIRL_FATHER_NAME_EN}} and Mrs. {{GIRL_MOTHER_NAME}}

The event will be held on:
Date: {{BROADCAST_WEDDING_DAY}}
Time: {{TIME}}
Place: {{BROADCAST_WEDDING_LOCATION}}

With any respect, here is our website invitation link:
{{LINK}}
*) Due to WhatsApp security reasons, if the link is not clickable (not blue), please save this number to be able to open the link.

Your presence will certainly be a great honor for us and our families. 

Yours truly,
{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family`,

  // for Bahasa Indonesia invitation - muslim
  ID_MUSLIM_INVITATION: `Assalamualaikum warahmatullahi wabarakatuh 

Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta’ala, kami bermaksud mengundang Bapak/Ibu/Saudara/i untuk hadir dalam acara pernikahan kami: 

*{{BOY_NAME}}*
{{BOY_FATHER_NAME_ID}} dan Ibu {{BOY_MOTHER_NAME}}_
& 
*{{GIRL_NAME}}*
{{GIRL_FATHER_NAME_ID}} dan Ibu {{GIRL_MOTHER_NAME}}_

Insha Allah acara akan diselenggarakan pada:
Hari: {{BROADCAST_WEDDING_DAY}}
Waktu: {{TIME}} 
Lokasi: {{BROADCAST_WEDDING_LOCATION}}

*Tanpa mengurangi rasa hormat, berikut link undangan website kami:*
{{LINK}} 
_*) Alasan keamanan WhatsApp, jika link tidak clickable (tidak berwarna biru), silakan simpan nomor ini untuk dapat membuka link._

Kehadiran Bapak/Ibu/Saudara/i tentu akan menjadi kehormatan besar bagi kami dan keluarga. Semoga kita semua dapat segera bertemu dengan keadaan sehat walafiat.

Wassalamualaikum Warahmatullahi Wabarakatuh 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_`,

  // for English Invitation - muslim
  EN_MUSLIM_INVITATION: `Assalamualaikum warahmatullahi wabarakatuh 

Dear Mr/Ms/Mrs,  
{{GUEST_NAME}}

Together with the blessing of Allah Subhanahu wa Ta’ala, we cordially request the honor of your presence at the wedding celebration of: 

{{BOY_NAME}}
{{BOY_FATHER_NAME_EN}} and Mrs. {{BOY_MOTHER_NAME}}
& 
{{GIRL_NAME}}
{{GIRL_FATHER_NAME_EN}} and Mrs. {{GIRL_MOTHER_NAME}}

Insha Allah the event will be held on:
Date: {{BROADCAST_WEDDING_DAY}}
Time: {{TIME}}
Place: {{BROADCAST_WEDDING_LOCATION}}

With any respect, here is our website invitation link:
{{LINK}}
*) Due to WhatsApp security reasons, if the link is not clickable (not blue), please save this number to be able to open the link.

Your presence will certainly be a great honor for our families and us. May Allah bless you.

Wassalamualaikum Warahmatullahi Wabarakatuh 

Yours truly,
{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family`,

  // -------------------------
  // Announcement broadcast
  // -------------------------
  ID_GENERAL_ANNOUNCEMENT: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Dengan segala kerendahan hati, kami bermaksud ingin mengumumkan acara pernikahan kami kepada Bapak/Ibu/Saudara/i: 

*{{BOY_NAME}}*
{{BOY_FATHER_NAME_ID}} dan Ibu {{BOY_MOTHER_NAME}}_
& 
*{{GIRL_NAME}}*
{{GIRL_FATHER_NAME_ID}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan diselenggarakan pada hari *{{BROADCAST_WEDDING_DAY}}*.

*Tanpa mengurangi rasa hormat, berikut link website pemberitahuan kami:*
{{LINK}}
_*) Alasan keamanan WhatsApp, jika link tidak clickable (tidak berwarna biru), silakan simpan nomor ini untuk dapat membuka link._

Dengan segala keterbatasan, kami mohon maaf jika belum bisa mengundang Bapak/Ibu/Saudara/i secara langsung. 

Terima kasih atas segala doa restu yang telah diberikan kepada kami. 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_`,

  EN_GENERAL_ANNOUNCEMENT: `Dear Mr/Ms/Mrs,
{{GUEST_NAME}}

Together with the families, we joyfully announce our marriage: 

{{BOY_NAME}}
{{BOY_FATHER_NAME_EN}} and Mrs. {{BOY_MOTHER_NAME}}
& 
{{GIRL_NAME}} 
{{GIRL_FATHER_NAME_EN}} and Mrs. {{GIRL_MOTHER_NAME}}

The event will be held on *{{BROADCAST_WEDDING_DAY}}*.

With any respect, here is our website announcement link:
{{LINK}}
*) Due to WhatsApp security reasons, if the link is not clickable (not blue), please save this number to be able to open the link.

With all conditions, we apologize if we are unable to invite you in person. 

Please know that your presence will be sincerely missed and we hope that you will be able to send us prayer from afar.  

Thank you for the kind words and understanding.

Yours truly,
{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family`,

  ID_MUSLIM_ANNOUNCEMENT: `Assalamualaikum warahmatullahi wabarakatuh 

Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta’ala, kami bermaksud ingin mengumumkan acara pernikahan kami kepada Bapak/Ibu/Saudara/i: 

*{{BOY_NAME}}*
{{BOY_FATHER_NAME_ID}} dan Ibu {{BOY_MOTHER_NAME}}_
& 
*{{GIRL_NAME}}*
{{GIRL_FATHER_NAME_ID}} dan Ibu {{GIRL_MOTHER_NAME}}_

Insha Allah acara akan diselenggarakan pada hari *{{BROADCAST_WEDDING_DAY}}*.

*Tanpa mengurangi rasa hormat, berikut link website pemberitahuan kami:*
{{LINK}}
_*) Alasan keamanan WhatsApp, jika link tidak clickable (tidak berwarna biru), silakan simpan nomor ini untuk dapat membuka link._

Dengan segala keterbatasan, kami mohon maaf jika belum bisa mengundang Bapak/Ibu/Saudara/i secara langsung. 

Terima kasih atas segala doa restu yang telah diberikan kepada kami. 

Wassalamualaikum Warahmatullahi Wabarakatuh 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_`,

  EN_MUSLIM_ANNOUNCEMENT: `Assalamualaikum warahmatullahi wabarakatuh 

Dear Mr/Ms/Mrs,  
{{GUEST_NAME}}

Together with the blessing of Allah Subhanahu wa Ta’ala, we joyfully announce our marriage: 

{{BOY_NAME}}
{{BOY_FATHER_NAME_EN}} and Mrs. {{BOY_MOTHER_NAME}}
& 
*{{GIRL_NAME}}* 
{{GIRL_FATHER_NAME_EN}} and Mrs. {{GIRL_MOTHER_NAME}}

Insha Allah the event will be held on *{{BROADCAST_WEDDING_DAY}}*.

With any respect, here is our website announcement link:
{{LINK}}
*) Due to WhatsApp security reasons, if the link is not clickable (not blue), please save this number to be able to open the link.

With all conditions, we apologize if we are unable to invite you in person. 

Please know that your presence will be sincerely missed and we hope that you will be able to send us prayer from afar.  

Thank you for the kind words and understanding.

Wassalamualaikum Warahmatullahi Wabarakatuh 

Yours truly,
{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family`,
};